<template>
  <div class="page">
    <div class="banner">
      <img class="img" src="@/static/become/peixun.png" />
    </div>
    <div class="content">
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          学院介绍
          <div class="left-icon"></div>
        </div>
      </div>
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          课程介绍
          <div class="left-icon"></div>
        </div>
        <div class="list">
          <div class="item" v-for="(item, i) in list" :key="i" @click="toInfo(item)">
            <img class="item-img" :src="$host + item.image" />
            <div class="item-title">{{ item.title }}</div>
            <div class="btn">查看详情</div>
          </div>
        </div>
      </div>
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          培训风采
          <div class="left-icon"></div>
        </div>
        <div class="show">
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      params: {
        page: 1,
        limit: 6,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.getcourselist(this.params).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.list = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    toInfo(data) {
      console.log(data)
      this.$router.push({
        name: "traininginfo",
        params: data
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height: 700px;
  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.view {
  width: 1200px;
  margin: 20px 0;
}

.title-view {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  height: 56px;
  color: #333;
  .left-icon {
    width: 120px;
    height: 5px;
    background: #fe6039;
    opacity: 0.75;
    border-radius: 2.5px;
    margin: 0 20px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  .item {
    width: 320px;
    margin: 10px 40px;
    cursor: pointer;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.04);
    height: 420px;
    .item-img {
      width: 320px;
      height: 320px;
    }
    .item-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      text-align: center;
      color: #333;
      font-weight: bold;
    }
    .btn {
      width: 120px;
      height: 40px;
      background: #fd9852;
      margin-top: 5px;
      margin-left: 100px;
      color: #fff;
    }
  }
}
.peixun-img {
    width: 200px;
    margin: 10px 50px;
  }
</style>
